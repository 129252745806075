import React from 'react';

export default function LoaderComponent() {
    return (
        <>
            <div className="overlay"></div>
            <div className='loader'>
                <a href="#" className="primary-btn" onClick={(e) => {
                    e.preventDefault();
                }}>
                    <img src={'loader.gif'} />
                    Loading, please wait...</a>
            </div>
        </>
    )
}
