const CommonUtils = {
    initials: (str: string): string => {
        let initials = '';
        if (str) {
            const strParts = str.trim().split(' ');
            initials = strParts[0][0] + (strParts.length > 1 ? strParts[1][0] : strParts[0][1]);
        }

        return initials.toUpperCase();
    },
    getTableIdentityPrefix: (maxSeats: number) => {
        const prefixes: any = {
            2: 'two',
            3: 'three',
            4: 'four',
            5: 'five',
            6: 'six',
            7: 'seven',
            8: 'eight',
        };

        return prefixes[maxSeats];
    },
    user_type: (user_type_id: number) => {
        const user_types: any = {
            1: 'Event Organizer',
            8: 'Attendee',
            3: 'Booth Admin',
            16: 'Event Organizer',
            18: 'Speaker',
            
        };

        return user_types[user_type_id];
    }
};

export default CommonUtils;