import { Reducer } from 'redux';
import { BoothActionTypes, BoothActions, ISeatDetailAction } from '../actions/boothActions';

export interface IBoothState {
    event_id: null | number;
    channel: IChannelState[];
    loading: boolean;
    errorMessage: string;
    infoMessage: string;
    PubNub: IPubNubState,
    mockLoginUser: {
        name: string,
        email: string,
        uuid: string,
        profilePic: string
    },
    seatDetail: ISeatDetailAction,
    pubnub_subscribed: any,
    meetingLink?: string;
    tableId?: number;
    seatsCounter?: any;
    isLoading: boolean;
    reloadRequired: boolean;
    unsubscribeTableId?: number;
    customizations?: any;
    userModal: boolean;
    userModalDetails?: any;
    moderatedModal: boolean;
    moderatedModalDetails?: any;
}

export interface ITablesResponse {
    tables: IChannelState[];
    settings: any;
}

export interface IChannelState {
    id: number,
    title: string,
    description: null | string,
    profile_picture: string,
    max_seats: number,
    meeting_link: string
    json_config: any,

}

export interface IPubNubState {
    publishKey: string,
    subscribeKey: string,
    uuid: string,
    presenceTimeout?: number;
    logVerbosity?: boolean;
}

export const initialBoothState: IBoothState = {
    PubNub: {
        publishKey: process.env.publishKey || 'pub-c-7c971242-ac85-4336-87e4-cd1307cf8b54',
        subscribeKey: process.env.subscriptKey || 'sub-c-8ee34c3e-a7b8-11ec-94c0-bed45dbe0fe1',
        uuid: process.env.uuid || 'a8248db2-4d79-46b6-8f48-b91f7a5180e2',
        // logVerbosity: true,
        // presenceTimeout: 30,
    },
    mockLoginUser: {
        name: "",
        email: "",
        uuid: '',
        profilePic: ''
    },
    event_id: null,
    channel: [],
    loading: false,
    errorMessage: '',
    infoMessage: '',
    seatDetail: {
        uuid: '',
        uuidSeat: '',
        name: ''
    },
    pubnub_subscribed: {},
    seatsCounter: {},
    isLoading: true,
    reloadRequired: false,
    customizations: {
        welcomeText: 'Welcome to the Networking Lounge',
        takeASeatText: 'Take a seat to join a conversation',
        headingTextColor: "#ffffff",
        subHeadingTextColor: "#ffffff",
        joinNowButtonText: 'Join Now',
        joinNowButtonBackground: 'rgb(10, 155, 216)',
        joinNowButtonForeColor: '#FFF',
        tableBackground: '#00293E',
        pageBackground: '#00293E', // could be url e.g. url('http://www.google.com/abc.png');
        tableAtCapacityButtonText: 'Table At Capacity',
        seatText: 'Seat',
        roundTableTileColor: '#214557',
        roundTableIconBgColor:'white'
    },
    userModal: false,
    userModalDetails: {
      firstname: "",
      lastname: "",
      profile_photo: "../logo.svg",
      email: "",
    },
    moderatedModal: false,
    moderatedModalDetails: {
      first_name: "",
      last_name: "",
      user_image: "../logo.svg",
      user_type_id: "",
      booth_name: "",
    },
};

// export const boothReducer: Reducer<IBoothState, BoothActions> = (
export const boothReducer: Reducer<any, any> = (
    state = initialBoothState,
    action
) => {
    switch (action.type) {
        case BoothActionTypes.PUBNUB_SUBSCRIBED: {
            return {
                ...state,
                pubnub_subscribed: action.pubnub_subscribed
            };
        }
        case BoothActionTypes.SEAT_DETAIL: {
            return {
                ...state,
                seatDetail: action.seatDetail
            };
        }
        case BoothActionTypes.RANDOM_BOOTH: {
            return {
                ...state,
                channel: action.channel
            };
        }
        case BoothActionTypes.LOAD_BOOTH: {
            return {
                ...state,
                loading: action.loading
            };
        }
        case BoothActionTypes.MOCK_LOGGED_IN_USER: {
            const mockLoggedInUser = action.mockLoggedInUser;
            return {
                ...state,
                mockLoginUser: mockLoggedInUser
            };
        }
        case BoothActionTypes.ERROR: {
            return {
                ...state,
                errorMessage: action.errorMessage,
                // channel: [],
                // event_id: null
            }
        }

        case BoothActionTypes.INFO: {
            return {
                ...state,
                infoMessage: action.infoMessage,
                // channel: [],
                // event_id: null
            }
        }

        case BoothActionTypes.CHOSEN_MEETING_DATA:
            const { meetingLink, tableId } = action.payload;
            return {
                ...state,
                meetingLink,
                tableId
            };
        case BoothActionTypes.SET_SEAT_COUNTER:
            return {
                ...state,
                seatsCounter: action.payload
            };
        case BoothActionTypes.IS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            };
        case BoothActionTypes.RELOAD_REQUIRED:
            return {
                ...state,
                reloadRequired: action.payload
            };
        case BoothActionTypes.UNSUBSCRIBE_TABLE:
            return {
                ...state,
                unsubscribeTableId: action.payload
            };
        case BoothActionTypes.CUSTOMIZATIONS:
            const customizations = Object.assign({}, state.customizations, action.payload);
            return {
                ...state,
                customizations
            };
        case BoothActionTypes.USER_MODAL:
            return {
                ...state,
                userModal: action.payload,
            };
        case BoothActionTypes.USER_MODAL_DETAILS: {
            const userModalDetails = action.userModalDetails;
            return {
                ...state,
                userModalDetails: userModalDetails,
            };
        }
        case BoothActionTypes.MODERATED_MODAL:
            return {
                ...state,
                moderatedModal: action.payload,
            };
        case BoothActionTypes.MODERATED_MODAL_DETAILS: {
            const moderatedModalDetails = action.moderatedModalDetails;
            return {
                ...state,
                moderatedModalDetails: moderatedModalDetails,
            };
        }
        default:
            return state;
    }
};
