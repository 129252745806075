import React from 'react';
import { useDispatch } from 'react-redux';
import { BoothActionTypes } from '../actions/boothActions';

export default function Alert({ type, content }: any) {
    const dispatch = useDispatch();

    const closeAlert = () =>{
        if(type === 'error'){
            dispatch({ type: BoothActionTypes.ERROR, errorMessage: null });
        }
        else if(type === 'info'){
            dispatch({ type: BoothActionTypes.INFO, errorMessage: null });
        }
    }


    switch (type) {
        case 'info':
            setTimeout(() => {
                dispatch({ type: BoothActionTypes.INFO, errorMessage: null });
            }, 8000);
            break;
        default:
        case 'error':
            setTimeout(() => {
                dispatch({ type: BoothActionTypes.ERROR, errorMessage: null });
            }, 8000);
            break;

    }

    return  (
        <div className={type + '_noti noti_wrapper'}>
            <span className='noti_cross' aria-hidden="true" onClick={closeAlert}>&times;</span>
            {content}
        </div>
    );
}