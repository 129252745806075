import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import theme from './theme';
import { Provider, useSelector } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom';
import configureStore, { IAppState } from './store/store';
import './App.css';

const store = configureStore();

// load variables from query string to localStorage
const url = new URL(window.location.href);
console.log(url);
if (url.host.indexOf('localhost:3000') === -1 && url && url.searchParams) {
    // uid=1&app_id=1234&name=Jahanzeb&profile_pic=
    // const userInfo = { name: 'Umar Ilyas', uuid: '12705034', email: 'umar@vfairs.com', logo: '', profilePic: '' };
    // let appId = 494;
    const userInfo = { name: '', uuid: '', email: '', logo: '', profilePic: '' };
    let appId = -1;
    if (url.searchParams.has('uid')) {
        userInfo.uuid = url.searchParams.get('uid') || '';
    }

    if (url.searchParams.has('name')) {
        userInfo.name = url.searchParams.get('name') || '';
    }

    if (url.searchParams.has('profile_pic')) {
        userInfo.profilePic = url.searchParams.get('profile_pic') || '';
    }

    if (url.searchParams.has('app_id')) {
        appId = Number(url.searchParams.get('app_id') ? url.searchParams.get('app_id') : -1);
    }

    localStorage.setItem('user_info', JSON.stringify(userInfo));
    localStorage.setItem('app_id', appId.toString());
}

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <Router>
                <App />
            </Router>
        </ThemeProvider>
    </Provider>,
    document.querySelector('#root'),
);
