import React, { useEffect, Fragment, useRef, useCallback, useMemo } from 'react';
import Table from './table.component';
import ModalComponent from './modal.component';
import useRoundtable from '../hooks/roundtable.hook';
import { useSelector } from 'react-redux';
import { IAppState } from '../store/store';

const defaultSeatObject = { uuid: '', name: '', uuidSeat: '' };

export default function TableWrapperComponent(): JSX.Element {
    const meetingLink = useSelector((state: IAppState) => state.boothState?.meetingLink);
    const chosenTableId = useSelector((state: IAppState) => state.boothState.tableId);
    const isLoading = useSelector((state: IAppState) => state.boothState.isLoading);
    const customizations = useSelector((state: IAppState) => state.boothState.customizations);
    let clearTimeout = useRef(-1);
    const get_tables=new URL(window.location.href).searchParams.get("show_table_ids");
    const only_table = get_tables!==null && get_tables!=="0" ? JSON.parse("[" +get_tables+ "]") : "";
    const {
        initTables,
        leaveSeat,
        channel,
        reserveSeat,
        loggedInUser,
        countSeats,
        occupySeat,
        showUserDetails,
        getModeratedDetails,
    } = useRoundtable();

    useEffect(() => {
        initTables();
    }, []);

    useEffect(() => {
        if (meetingLink) {
            // debounce if second time this hook is called for some reason
            if (clearTimeout.current > -1) {
                return;
            }

            clearTimeout.current = window.setTimeout(() => {
                const modal = document.getElementById("hiddenModal");
                modal?.click();
                clearTimeout.current = -1;
            }, 50);
        }
    }, [meetingLink]);

    const memoizedModal = useCallback(() => (
        <ModalComponent
            handleClose={leaveSeat}
            tableId={chosenTableId}
            meetingLink={meetingLink}
            displayName={loggedInUser?.name}
            userDetail={{}}
            avatarUrl={loggedInUser.profilePic}
            tables={channel}
        />
    ), [meetingLink]);

    // const beforeUnload = (event: any) => {
    //     if (meetingLink) {
    //         event.preventDefault();
    //         event.stopPropagation();
    //         handleClose(chosenTableId);
    //         event.returnValue = 'You are in a call, please leave the call and close the popup before closing the browser window.';
    //     }

    //     return false;
    // }

    return (
        <>
            {
                channel && channel.map(singleTable => {
                    // const _join_room = reserveSeat === singleTable.id ? "Leave Now" : "Join Now";
                    if(only_table!==""){
                        const found_table_id = only_table.find((table_id :any) => table_id === singleTable.id);
                        if(found_table_id===undefined){
                            return false;
                        }
                    }
                    return (
                        
                        <Fragment key={`table-${singleTable.id}`}>
                            <Table
                                key={`channel-${singleTable.id}`}
                                table={singleTable}
                                _join_room={(reserveSeat === -1 || chosenTableId !== singleTable.id) ? customizations?.joinNowButtonText : "Leave Now"}
                                countSeats={countSeats}
                                occupySeat={occupySeat}
                                showUserDetails={showUserDetails}
                                getModeratedDetails={getModeratedDetails}
                            />
                            
                        </Fragment>
                    )
                })
            }

            {
                meetingLink && memoizedModal()
            }
        </>
    );
}