import React, { useState } from "react";
import '../ModeratePopup.css';
import CommonUtils from "../utils/common.utils";

function ModeratedModalComponent({ moderatedModalDetails }: any) {
  const colors = ['#ac1917', '#b75420', '#768b45', '#27958f', '#704776', '#1887ab', '#d34467', '#2b9300' ,'#007bff', '#6495ED','#ff8000','#ff00bf','#2b4700','#495057','#725348','#ef380e'];


  return (
    <>
      <button
        type="button"
        id="hiddenModeratedModal"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-toggle="modal"
        data-target="#moderatedModal"
      >
        Launch demo moderated modal
      </button>
      <div className="moderate-popup-trigger"><a href="#moderatedModal" data-toggle="modal"></a></div>
      <div className="modal fade myModal" id="moderatedModal" role="dialog" aria-labelledby="exampleModalLabel" data-backdrop="static" data-keyboard="false" aria-hidden="true">
        <div className="modal-dialog meetingModal" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4>Moderated By</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{color: "red"}}><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              <div className="moderate-list session-speakers">
                 
                  {moderatedModalDetails && moderatedModalDetails.map((value:any, index:number) => (

                      <div className="col-xs-12 col-md-4" key={index}>
                        <div className="sp-box">
                          {(value.user_image!=="" && value.user_image!==null) ? <img src={value.user_image } />:
                              <span style={{background:colors[index]}}> {CommonUtils.initials(value.first_name+' '+value.last_name)}</span>
                          }
                            <div className="sp-details">
                              <h4>{value.first_name+' '+value.last_name}</h4>
                              {value.user_type_id==="3" ? <p>{value.booth_name}</p>: "" }
                              <p>{CommonUtils.user_type(value.user_type_id)}</p>
                            </div>
                        </div>
                      </div>
                      
                    ) 
                  )}
                  


              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModeratedModalComponent;
