import React, { useState } from "react";
import CommonUtils from "../utils/common.utils";

function UserModalComponent({ userModalDetails }: any) {
  const [showProfile, setShowProfile] = useState(true);

  let profile_pic=userModalDetails[0].profile_photo;
  let firstname=userModalDetails[0].extra.first_name;
  let lastname=userModalDetails[0].extra.last_name;
  let email=userModalDetails[0].email;
  let booth_name=userModalDetails[0].extra.booth_name;
  const details = [];

  let k: keyof typeof userModalDetails[0];  // Type is "one" | "two" | "three"
  for (k in userModalDetails[0]) {
    if(k!==undefined){
      if(k!== 'first_name' && k!== 'last_name' && k!== 'email'&& k!== 'extra' && k!== 'resume'&& k!== 'user_type' && k!== 'profile'  ){
        const v = userModalDetails[0][k];  // OK
        details.push((v.value !== undefined && v.value !== null)  ? `${v.title}: ${v.value}` : "");
      }
     
    }
   }
  
  

  return (
    <>
      <button
        type="button"
        id="hiddenUserModal"
        className="btn btn-primary"
        style={{ display: "none" }}
        data-toggle="modal"
        data-target="#userDetailModal"
      >
        Launch demo user modal
      </button>
      <div
        className="modal fade"
        id="userDetailModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className={`modal-dialog`} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-xs-12 col-md-3">
                  {profile_pic && (
                    <img
                      src={profile_pic}
                      alt="N"
                      className="img-fluid"
                      
                    />
                  )}
                  <p style={{ textAlign: "center" }}>{CommonUtils.user_type(userModalDetails[0].user_type)}</p>
                  <p style={{ textAlign: "center" }}>{userModalDetails[0].user_type===3 ? booth_name: "" }</p>  

                </div>
                <div className="col-xs-12 col-md-9">
                  <div className="user-info">
                  <h4 >{firstname!=="" ? firstname: "" } {lastname!=="" ? lastname: "" }</h4>  
                 
                  {email && (
                    <p>Email: {email}</p>
                  )}
                  <hr></hr>

                  {showProfile && details.map((value, index) => ( 
                    <div className="content" dangerouslySetInnerHTML={{__html: value}}></div>
                  ) 
                  )}
                  
                    
                  </div>
                  {/* <a href="#" className="primary-btn">
                    See Full Profile
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserModalComponent;
