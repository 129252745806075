import React from 'react';
import { Route } from "react-router-dom";
import IndexPage from './pages/indexPage';
import { useSelector } from "react-redux";
import { IAppState } from "./store/store";
import { APP_VERSION } from './constants/redis-message.constants';

function App() {
    console.log("RT APP VERSION: ",APP_VERSION)
    return (
        <div>
            <Route path="/" exact component={IndexPage} />
        </div>
    );
}

export default App;
