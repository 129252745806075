import axios from 'axios';

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
export function useHttpService() {
    const postData = async (url = '', data = {}) => {
        // Default options are marked with *
        return axios.post(url, data).then(resp => resp.data);
    }

    return {
        post: postData
    };
}