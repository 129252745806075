import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';

import BoothComponent from '../components/booth.component';
import LoaderComponent from '../components/loader.component';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../store/store';
import Alert from '../components/alert.component';
import { loadBoothAction, loadBoothData } from '../actions/boothActions';
import UserModalComponent from '../components/user-modal.component';
import ModeratedModalComponent from '../components/moderated-modal.component';

const IndexPage = () => {
    const isLoading = useSelector((state: IAppState) => state.boothState.isLoading);
    const errorMessage = useSelector((state: IAppState) => state.boothState.errorMessage);
    const infoMessage = useSelector((state: IAppState) => state.boothState.infoMessage);
    const customizations = useSelector((state: IAppState) => state.boothState.customizations);
    const userModal = useSelector((state: IAppState) => state.boothState.userModal);
    const userModalDetails = useSelector((state: IAppState) => state.boothState.userModalDetails);
    const moderatedModal = useSelector((state: IAppState) => state.boothState.moderatedModal);
    const moderatedModalDetails = useSelector((state: IAppState) => state.boothState.moderatedModalDetails);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadBoothAction(true))
        dispatch(loadBoothData(() => {
            console.log('callback');
        }))
    }, []);

    useEffect(() => {
        const body = document.getElementsByTagName('body');
        if (customizations?.pageBackground) {
            const bg = customizations?.pageBackground;
            if (bg.indexOf('http') > -1) {
                body[0].style.background = `url(${bg})`;
            } else {
                body[0].style.background = bg;
            }
        }

    }, [customizations]);

    return (
        <>
            <style dangerouslySetInnerHTML={{
            __html: [
                '.table:after {',
                '  background: '+customizations?.tableBackground+' !important',
                '}'
                ].join('\n')
            }}>
            </style>

            <div className="section title">
                <div className="container">
                    <h1 style={{color:customizations?.headingTextColor}}    dangerouslySetInnerHTML={{ __html: customizations?.welcomeText }}></h1>
                    <h2 style={{color:customizations?.subHeadingTextColor}} dangerouslySetInnerHTML={{ __html: customizations?.takeASeatText }}></h2>
                </div>
            </div >
            <Container className='section cards-con'>
                <div className="container">
                    <div className="row">
                        <BoothComponent />
                    </div>
                </div>
            </Container>
            {userModal && <UserModalComponent userModalDetails={userModalDetails} />}
            {isLoading && <LoaderComponent />}
            {errorMessage && <Alert type={'error'} content={errorMessage}  />}
            {infoMessage && <Alert type={'info'} content={infoMessage}  />}
            {moderatedModal && <ModeratedModalComponent moderatedModalDetails={moderatedModalDetails} />}
            
        </>
    );
}

export default IndexPage;