//This is an example component so you can get things going and take a look at some of Material-UIs features.
//You should probably delete or modify this to start your project!
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IAppState } from '../store/store';
import { loadBoothAction } from '../actions/boothActions';
import Tables from './table-wrapper.component';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';

const setUserInfo = () => {
    const url = new URL(window.location.href);
    console.log(url);
    if (url.host.indexOf('localhost:3000') === -1 && url && url.searchParams) {
        // uid=1&app_id=1234&name=Jahanzeb&profile_pic=
        // const userInfo = { name: 'Umar Ilyas', uuid: '12705034', email: 'umar@vfairs.com', logo: '', profilePic: '' };
        // let appId = 494;
        const userInfo = { name: '', uuid: '', email: '', logo: '', profilePic: '' };
        let appId = -1;
        if (url.searchParams.has('uid')) {
            userInfo.uuid = url.searchParams.get('uid') || '';
        }

        if (url.searchParams.has('name')) {
            userInfo.name = url.searchParams.get('name') || '';
        }

        if (url.searchParams.has('profile_pic')) {
            userInfo.profilePic = url.searchParams.get('profile_pic') || '';
        }

        if (url.searchParams.has('app_id')) {
            appId = Number(url.searchParams.get('app_id') ? url.searchParams.get('app_id') : -1);
        }

        localStorage.setItem('user_info', JSON.stringify(userInfo));
        localStorage.setItem('app_id', appId.toString());
    }
}

let userInfo = localStorage.getItem('user_info') || '';
if (!userInfo) {
    setUserInfo();
    userInfo = localStorage.getItem('user_info') || '';
    if (!userInfo) {
        alert('User not found. Please try to relogin.')
    }
}
const parsedUserInfo = JSON.parse(userInfo);
const pubnub = new PubNub({
    publishKey: process.env.publishKey || 'pub-c-7c971242-ac85-4336-87e4-cd1307cf8b54',
    subscribeKey: process.env.subscriptKey || 'sub-c-8ee34c3e-a7b8-11ec-94c0-bed45dbe0fe1',
    uuid: parsedUserInfo.uuid
});

export default function BoothComponent(): JSX.Element {
    //here we declare what we want to take from the redux store with the useSelector() hook
    //every time one of these properties is updated on the store, our component will re-render to reflect it
    const channel = useSelector((state: IAppState) => state.boothState.channel);
    const mockLoginUser = useSelector((state: IAppState) => JSON.parse(JSON.stringify(state.boothState.mockLoginUser)));
    const pubNubState = useSelector((state: IAppState) => state.boothState.PubNub);
    //this hook allows us to access the dispatch function
    const dispatch = useDispatch();
    useEffect(() => {
        console.log('Calling for fetching data');

    }, [])

    useEffect(() => {
        mockLoginUser.name && dispatch(loadBoothAction(false))
    }, [JSON.stringify(mockLoginUser)])

    const initializeApp = () => {
        return (<PubNubProvider client={pubnub}>
            <Tables />
        </PubNubProvider>
        );
    }

    //here we define simple stateless components for the card image and error messages
    //notice how we dispatch the call to end the loading of the image based on the img element's onLoad event

    return (
        <>
            {mockLoginUser.name && channel && channel.length > 0 && initializeApp()}
        </>
    );
}